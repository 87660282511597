import '@fontsource/poppins/400.css';
import '@fontsource/poppins/400-italic.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/600-italic.css';
import '@fontsource/poppins/700.css';
import './src/styles/global.css'
import React from 'react';

console.log('Howdy fellow nerd, hope you are having a mighty fine day! 🙌 🚀 🎉');

export const wrapRootElement = ({ element }) => (
  <>
    {element}
  </>
);
